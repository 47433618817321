@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700;800;900&display=swap');
@import './partials/color';
@import './partials/variables';

* {
    margin: 0;
    padding: 0;
    text-decoration: none;
    &:hover{
        text-decoration: none;
    }
}

a{
    text-decoration: none;
    &:hover{
        text-decoration: none;
    }
}

body{
    position: relative;
    padding: 0;
    margin: 0;
    font-size: 16px;
    font-weight: 400;
    line-height: normal;
    color: var(--black);
    font-family: 'Inter', sans-serif;
    background-color: var(--white);
    overflow-x: hidden;
    &::-webkit-scrollbar {
        width: 10px;
    }

    &::-webkit-scrollbar-track {
        box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    }

    &::-webkit-scrollbar-thumb {
        background-color: var(--clater);
    }
}

h1,h2,h3,h4,h5,h6,p,span.b,strong,small{
    padding: 0;
    margin: 0;
}

h1,h2,h3,h4,h5,h6{
    font-family: 'Inter', sans-serif;
    color: var(--clater);
}

section{
    padding: 60px 0;
}

.wrapper{
    position: relative; 
}
.fly {
    position: fixed;
    width: auto;
    height: auto;
    bottom: 20px;
    right: 20px;
    color: #FFF;
    border-radius: 50px;
    text-align: center;
    font-size: 30px;
    z-index: 100;

    &:focus {
        box-shadow: none;
        outline: none;
    }

    li{
        margin: 5px 0;
        a{
            img{
                filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
            }
        }
        &:first-child{
            margin-top: 0;
        }
        &:last-child{
            margin-bottom: 0;
        }
        .wa{
            background: transparent;
            border: transparent;
        }
    }
}

.firstHeading {
    border: 0!important;
    clip: rect(0 0 0 0)!important;
    height: 0.0625rem!important;
    margin: -0.0625rem!important;
    overflow: hidden!important;
    padding: 0!important;
    position: absolute!important;
    width: 0.0625rem!important;
}


@import './base/base';