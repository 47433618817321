.testimoni {
	position: relative;
	.section-body{
		.card{
			border: 1px solid var(--clater);
			border-top-left-radius: 0;
			border-top-right-radius: 0;
			border-bottom-left-radius: 20px;
			border-bottom-right-radius: 20px;
			.card-header{
				background-color: var(--clater);
				border-radius: 0;
				color: var(--white);
				text-align: center;
				p{
					font-size: 16px;
				}
				span{
					font-size: 14px;
					font-style: italic;
				}
			}
			.card-body{
				position: relative;
				display: flex;
				align-items: center;
				justify-content: center;
				p{
					text-align: center;
				}
			}
		}
	}
	form{
		.form-control{
			margin-top: 10px;
			&:active, &:focus, &:hover{
				box-shadow: none;
				border: 1px solid var(--clater);
			}
		}
	}
}