#header{
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 999;
    background-color: hsla(0,0%,100%,.5647058823529412);
    background-color: var(--clater);
    -webkit-backdrop-filter: saturate(180%) blur(20px);
    backdrop-filter: saturate(180%) blur(20px);
    transition: background-color .5s cubic-bezier(.28,.11,.32,1);
    transition-property: background-color,-webkit-backdrop-filter;
    transition-property: background-color,backdrop-filter;
    transition-property: background-color,backdrop-filter,-webkit-backdrop-filter;
    .navbar{
        padding: 0;
        .navbar-brand{
            margin: 0;
        }
        .navbar-collapse{
            .navbar-nav{
                position: relative;
                .nav-item{
                    position: relative;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    &:hover{
                        .nav-link{
                            color: var(--clater);
                        }
                        &::after{
                            opacity: 1;
                            visibility: visible;
                            height: 100%;
                        }
                    }
                    &::after{
                        display: block;
                        position: absolute;
                        left: 0;
                        bottom: 0;
                        right: 0;
                        margin: auto;
                        width: 100%;
                        height: 0px;
                        content: '.';
                        color: transparent;
                        background: #FFFFFF;
                        visibility: none;
                        opacity: 0;
                        z-index: -1;
                        -webkit-transition: all .3s;
                        -moz-transition: all .3s;
                        -o-transition: all .3s;
                        transition: all .3s
                    }
                    &.dropdown{
                        .dropdown-toggle{
                            &::after{
                                content: '';
                                border: 0;
                            }
                        }
                        .dropdown-menu {
                            transition: all 0.5s;
                            overflow: hidden;
                            transform-origin: top center;
                            transform: scale(1, 0);
                            display: flex;
                            border: 0px solid transparent;
                            background-color: #FFFFFF;
                            border-radius: 0;
                            z-index: 1;
                            left: 0;
                            box-shadow: 0px 2px 11px #00000010;
                            .dropdown-item {
                                font-size: 14px;
                                padding: 8px 10px !important;
                                color: var(--clater);
                                font-weight: 500;
                                background-color: #FFFFFF;
                                text-transform: capitalize;
                                &.active, &:hover{
                                    border-bottom: 1px solid var(--clater);
                                }
                            }
                        }
                        &:hover{
                            .dropdown-menu {
                                transform: scale(1);
                            }
                        }
                    }
                    .nav-link{
                        position: relative;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        font-size: 14px;
                        text-transform: uppercase;
                        color: var(--white);
                        z-index: 1;
                        &.active{
                            position: relative; 
                            color: var(--clater);
                            background-color: #ffffff;
                            height: 100%;
                            width: 100%;
                        }
                    }
                    .call{
                        padding: 0 10px;
                        font-size: 14px;
                        text-transform: uppercase;
                        color: var(--white);
                    }
                    &.phone{
                        border: 1px solid var(--white);
                        border-radius: 20px;
                        &::after{
                            content: '';
                            display: none;
                        }
                        &:hover{
                            color: var(--clater);
                        }
                    }
                    &.lang{
                        width: 40px;
                        height: 40px;
                        border: 1px solid var(--white);
                        border-radius: 100%;
                        text-align: center;
                        &::after{
                            content: '';
                            display: none;
                        }
                        button{
                            padding: 0;
                            &:focus, &:hover{
                                outline: none;
                                box-shadow: none;
                            }
                        }
                        &.lang-dropdown{
                            .lang-dropdown-menu{
                                padding: 0;
                                transform: scaley(0);
                                position: absolute;
                                top: 0;
                                opacity: 1;
                                right: 0;
                                left: 0;
                                justify-content: center;
                                &.show{
                                    transform: scaley(1);
                                    border: 1px solid var(--clater);
                                }
                            }
                        }
                    }
                    .dropdown-toggle{
                        background-color: transparent;
                        border-color: transparent;
                        &::after{
                            content: '';
                            display: none;
                        }
                    }
                    .dropdown-menu{
                        min-width: 100%;
                        border: 1px solid var(--clater);
                        border-radius: 0;
                        ul{
                            li{
                                margin: 10px 0;
                                &:first-child{
                                    margin-top: 0;
                                }
                                &:last-child{
                                    margin-bottom: 0;
                                }
                            }
                        }
                    }
                }
                // .dropdown-menu{
                //     display: flex;
                // }
                .dropdown{
                    .dropdown-toggle{
                        &:focus, &:hover{
                            outline: none;
                            box-shadow: none;
                        }
                        &::after{
                            content: '';
                            display: none;
                        }
                    }
                    .dropdown-menu{
                        min-width: 0;
                        .dropdown-item{
                            padding: 0;
                            background-color: #ffffff;
                            &:focus, &:hover{
                                outline: none;
                                box-shadow: none;
                                background-color: #FFFFFF;
                            }
                        }
                    }
                }
            }
        }
        .navbar-toggler{
            border: 0;
            i{
                color: #ffffff;
            }
            &:hover, &:active, &:focus{
                outline: none;
                box-shadow: none;
            }
        }
        .dropdown{
            .dropdown-toggle{
                &:focus, &:hover{
                    outline: none;
                    box-shadow: none;
                }
                &::after{
                    content: '';
                    display: none;
                }
            }
            .dropdown-menu{
                min-width: 0;
                .dropdown-item{
                    padding: 0;
                    background-color: #ffffff;
                    &:focus, &:hover{
                        outline: none;
                        box-shadow: none;
                        background-color: #FFFFFF;
                    }
                }
            }
        }
    }
}
@media screen and (max-width: 961px) {
    #header{
        background-color: var(--clater);
        .navbar{
            position: relative;
            .mobile_menu{
                background-color: var(--clater);
                position: absolute;
                right: -334px;
                top: 0;
                z-index: 100;
                height: 100vh;
                width: 90%;
                display: block !important;
                text-align: left;
                opacity: 0;
                transition: all 500ms;
                transform: scaleX(0);
                padding: 1rem;
                overflow: hidden;
                overflow-y: hidden;
                .nav-link{
                    color: #FFFFFF;
                }
                .nav-item{
                    padding: 1rem;
                    .call{
                        padding: 0 10px;
                        font-size: 14px;
                        text-transform: uppercase;
                        color: var(--white);
                    }
                    &.phone{
                        border: 1px solid var(--white);
                        border-radius: 20px;
                        &::after{
                            content: '';
                            display: none;
                        }
                        &:hover{
                            color: var(--clater);
                        }
                    }
                    &.lang{
                        width: 40px;
                        height: 40px;
                        border: 1px solid var(--white);
                        border-radius: 100%;
                        text-align: center;
                        &::after{
                            content: '';
                            display: none;
                        }
                        button{
                            padding: 0;
                            width: 40px;
                            height: 40px;
                            border-radius: 100%;
                            color: var(--white);
                            &.active{
                                background-color: var(--white);
                                color: var(--clater);
                            }
                            &:hover, &:focus{
                                outline: none;
                                box-shadow: none;
                                border-radius: 20px;
                                background-color: var(--white);
                                color: var(--clater);
                                border-radius: 100%;
                            }
                        }
                    }
                }
                &.show{
                    position: absolute;
                    top: 0;
                    opacity: 1;
                    right: 0;
                    transform: scaleX(1);
                }
                .dropdown-menu{
                    padding: 0;
                    border: none;
                    border-radius: 0;
                    .dropdown-item{
                        padding: .5rem .5rem;
                        color: var(--clater);
                    }
                }
            }
        }
    }
}

@-webkit-keyframes slideInDown{
    0% {
        -webkit-transform: translate3d(0,-100%,0);
        -moz-transform: translate3d(0,-100%,0);
        -o-transform: translate3d(0,-100%,0);
        transform: translate3d(0,-100%,0);
        visibility: visible;
    }
    100% {
        -webkit-transform: translateZ(0);
        -moz-transform: translateZ(0);
        -o-transform: translateZ(0);
        transform: translateZ(0);
    }
}

@-moz-keyframes slideInDown{
    0% {
        -webkit-transform: translate3d(0,-100%,0);
        -moz-transform: translate3d(0,-100%,0);
        -o-transform: translate3d(0,-100%,0);
        transform: translate3d(0,-100%,0);
        visibility: visible;
    }
    100% {
        -webkit-transform: translateZ(0);
        -moz-transform: translateZ(0);
        -o-transform: translateZ(0);
        transform: translateZ(0);
    }
}

@-o-keyframes slideInDown{
    0% {
        -webkit-transform: translate3d(0,-100%,0);
        -moz-transform: translate3d(0,-100%,0);
        -o-transform: translate3d(0,-100%,0);
        transform: translate3d(0,-100%,0);
        visibility: visible;
    }
    100% {
        -webkit-transform: translateZ(0);
        -moz-transform: translateZ(0);
        -o-transform: translateZ(0);
        transform: translateZ(0);
    }
}

@keyframes slideInDown{
    0% {
        -webkit-transform: translate3d(0,-100%,0);
        -moz-transform: translate3d(0,-100%,0);
        -o-transform: translate3d(0,-100%,0);
        transform: translate3d(0,-100%,0);
        visibility: visible;
    }
    100% {
        -webkit-transform: translateZ(0);
        -moz-transform: translateZ(0);
        -o-transform: translateZ(0);
        transform: translateZ(0);
    }
}