.hero {
	position: relative;
	padding: 0;
	background-color: var(--clater);

	.owl-carousel {
		.owl-item {
			.item {
				margin: 0;
				position: relative;
				width: 100%;
				height: 100vh;
				background-position: center center;
				background-repeat: no-repeat;
				background-size: cover;

				.overlay {
					position: absolute;
					width: 100%;
					height: 100vh;
					background-color: #00000030;
				}
			}
		}

		.owl-dots {
			text-align: left;
			position: absolute;
			width: 100%;
			bottom: -10px;
			left: 0px;
			margin: 20px 0px !important;
			z-index: 999;
			display: flex;
			align-items: center;

			.owl-dot {
				span {
					background-color: var(--clater);
					width: 10px;
					height: 10px;
					border-radius: 100%;
				}

				&.active {
					span {
						width: 15px;
						height: 15px;
					}
				}
			}
		}
	}

	.banner-content {
		position: absolute;
		width: 100%;
		z-index: 10;
		display: flex;
		align-items: center;
		justify-content: center;

		.hero-content {
			position: relative;
			width: 100%;
			overflow: hidden;

			h2 {
				font-size: 38px;
				color: #FFFFFF;
				margin: 1rem 0;
				font-weight: bold;
			}

			p {
				font-size: 24px;
				font-weight: 400;
				color: #FFFFFF;
			}
		}
	}
}

.hero_loading {
	.react-loading-skeleton {
		height: 100vh;
	}
}

.view {
	position: relative;

	.box {
		position: relative;
		display: flex;
		align-items: flex-end;
		justify-content: center;

		.box-body {
			position: absolute;
			padding-bottom: 2rem;
			color: var(--white);
		}
	}
}

.fasilitas {
	position: relative;

	.box {
		position: relative;
		display: flex;
		align-items: flex-end;
		justify-content: center;

		.box-body {
			position: absolute;
			color: var(--white);
			font-size: 14px;
		}
	}
}

.tenant {
	position: relative;

	.item-tenant {
		position: relative;
		width: 170px;
		height: 170px;
		display: flex;
		align-items: center;
		justify-content: center;
	}
}

.area-testimoni {
	position: relative;

	.owl-carousel {
		.owl-stage {
			display: flex;
		}

		.owl-item {
			display: grid;
			flex: 1 0 auto;
		}

		.card-body {
			display: flex;
			flex: 1 0 auto;
			flex-direction: column;
		}

		.card-text {
			flex-grow: 1
		}

		.card {
			display: flex;
			flex-direction: column;
			margin: 0 15px;
		}
	}

	// .owl-item{
	// 	width: 100%;
	// 	min-height: 300px;
	// 	margin: 0 auto;
	// 	display: -webkit-flex; /* Safari */     
	// 	display: flex; /* Standard syntax */
	// 	.card{
	// 		-webkit-flex: 1; /* Safari */
	// 		-ms-flex: 1; /* IE 10 */
	// 		flex: 1; /* Standard syntax */
	// 	}
	// }
	.card {
		border: 1px solid var(--clater);
		border-top-left-radius: 0;
		border-top-right-radius: 0;
		border-bottom-left-radius: 20px;
		border-bottom-right-radius: 20px;

		.card-header {
			background-color: var(--clater);
			border-radius: 0;
			color: var(--white);
			text-align: center;

			p {
				font-size: 16px;
			}

			span {
				font-size: 14px;
				font-style: italic;
			}
		}

		.card-body {
			position: relative;
			display: flex;
			align-items: center;
			justify-content: center;

			p {
				text-align: center;
			}
		}
	}

	.owl-dots {
		display: flex;
		align-items: center;
		justify-content: center;

		.owl-dot {
			span {
				width: 25px;
				height: 25px;
				border-radius: 100%;
				background: rgba(134, 24, 49, 0.8);
				border: 4px solid rgba(255, 255, 255, 0.8);
			}

			&.active,
			&:hover {
				span {
					width: 25px;
					height: 25px;
					background-color: var(--clater);
					border: 4px solid rgba(134, 24, 49, 1);
				}
			}
		}
	}
}