.about{
    position: relative;
    .address{
        .title{
            color: var(--clater);
            font-size: 1.75rem;
        }
        p{
            font-size: 20px;
        }
    }
    .section-heading{
        margin-bottom: 20px;
    }
    .section-body{
        p{
            margin-bottom: 16px;
        }
    }
}