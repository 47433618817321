.news {
    position: relative;

    .card {
        border: 1px solid #E5E5E5;
        box-sizing: border-box;
        border-radius: 0;

        .card-img-top {
            border-top-left-radius: 0;
            border-top-right-radius: 0;
        }

        .card-body {
            padding: 20px 10px;

            .card-title {
                color: #867BFF;
                font-size: 20px;
                margin-bottom: 20px;
                display: -webkit-box;
                max-height: 3.4rem;
                -webkit-box-orient: vertical;
                overflow: hidden;
                text-overflow: ellipsis;
                -webkit-line-clamp: 2;
                letter-spacing: normal;
            }

            .card-text {
                font-size: 14px;display: -webkit-box;
                max-height: 3.4rem;
                -webkit-box-orient: vertical;
                overflow: hidden;
                text-overflow: ellipsis;
                -webkit-line-clamp: 3;
                letter-spacing: normal;
            }
        }
    }
}

.detail-article{
    position: relative;
    .section-heading{
        margin-bottom: 2rem;
        h1{
            margin-bottom: 1rem;
        }
        p{
            color: #C4C4C4;
        }
    }
    .section-body{
        position: relative;
        .thumbnail {
            width: 100%;
            height: auto;
        }
        .content{
            p{
                margin-bottom: 1rem;
            }
        }
        .other_article{
            position: relative;
            .card-body {
                padding: 20px 10px;
    
                .card-title {
                    color: #867BFF;
                    font-size: 20px;
                    margin-bottom: 20px;
                    display: -webkit-box;
                    max-height: 3.4rem;
                    -webkit-box-orient: vertical;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    -webkit-line-clamp: 2;
                    letter-spacing: normal;
                }
    
                .card-text {
                    font-size: 14px;display: -webkit-box;
                    max-height: 3.4rem;
                    -webkit-box-orient: vertical;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    -webkit-line-clamp: 3;
                    letter-spacing: normal;
                }
            }
        }
    }
}