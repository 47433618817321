footer{
    position: relative;
    background: var(--clater);
    font-family: 'Inter', sans-serif;
    font-weight: 400;
    color: var(--white);
    .contact{
        li{
            margin: 30px 0;
            &:first-child{
                margin-top: 0;
            }
            &:last-child{
                margin-bottom: 0;
            }
        }
    }
    .menu{
        columns: 2;
        -webkit-columns: 2;
        -moz-columns: 2;
        -moz-columns: 2;
        li{
            margin: 30px 0;
            &:first-child{
                margin-top: 0;
            }
            &:last-child{
                margin-bottom: 0;
            }
        }
    }
    .informasi{
        font-size: 12px;
    }
    hr{
        margin: 0;
    }
    .copyright{
        padding: 20px;
		background-color: var(--white);
        p{
            font-size: 14px;
			color: var(--clater);
            a{
                color: var(--clater);
            }
        }
    }
}