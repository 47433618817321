.benefit {
    position: relative;

    .section-body {
        ul {
            display: flex;
            flex-wrap: wrap;
            list-style: none;
        }

        li {
            height: 40vh;
            flex-grow: 1;
            padding: 10px;
        }

        li:last-child {
            // There's no science in using "10" here. In all my testing, this delivered the best results.
            flex-grow: 8;
        }

        img {
            max-height: 100%;
            min-width: 100%;
            object-fit: cover;
            vertical-align: bottom;
        }
        .box {
            position: relative;
            display: flex;
            align-items: flex-end;
            justify-content: center;
    
            .box-body {
                position: absolute;
                padding-bottom: 2rem;
                color: var(--white);
            }
        }
    }
    .item-full{
        position: relative;
        img{
            width: 100%;
        }
    }
}