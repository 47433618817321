.list_our-space{
    position: relative;
    .section-heading{
        h2{
            font-size: 20px;
            color: var(--black);
        }
    }
    .list-space{
        position: relative;
        ul{
            position: absolute;
            margin: 0 auto;
            width: 100%;
            padding: 0;
            list-style: none;
            bottom: 5%;
            display: flex;
            justify-content: space-between;
            z-index: 1;
            li{
                margin: 0 20px;
                color: var(--white);
                text-align: center;
                p{
                    font-size: 14px;
                    &:first-child{
                        font-weight: bold;
                    }
                    &:last-child{
                        padding-top: 10px;
                        font-weight: normal;
                    }
                }
            }
        }
    }
    .list-space{
        .owl-carousel{
            .owl-item{
                .item{
                    position: relative;
                    .overlay {
                        background: linear-gradient(180deg, rgba(134, 24, 49, 0) 0%, #861831 100%);
                        position: absolute;
                        width: 100%;
                        height: 99px;
                        bottom: 0;
                        opacity: 1;
                        padding-top: 1.5rem;

                        
                    }
                }
            }
            .owl-nav{
                margin: 0;
                > div{
                    position: absolute;
                    top: 50%;
                    background: transparent;
                    font-size: 30px;
                    &.owl-prev{
                        left: 0;
                    }
                    &.owl-next{
                        right: 0;
                    }
                }
            }
        }
    }
}
.modal-content{
    border-radius: 0;
    border: 1px solid #861831;
    .modal-header, .modal-footer{
        border: none;
    }
    .modal-body{
        .list-space{
            .owl-carousel{
                .owl-nav{
                    margin: 0;
                    > div{
                        position: absolute;
                        top: 50%;
                        background: transparent;
                        font-size: 30px;
                        &.owl-prev{
                            left: 0;
                        }
                        &.owl-next{
                            right: 0;
                        }
                    }
                }
            }
        }
        .bold{
            font-weight: bold;
        }
    }
}

.our-space-detail{
    ul{
        list-style: none;
        padding-left: 0;
    }
}