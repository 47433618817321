.floor{
    position: relative;
    .section-heading{
        h2{
            text-transform: uppercase;
            sup{
                text-transform: none;
            }
        }
    }
    hr{
        margin: 3rem 0;
        background-color: #FFFFFF;
    }
}
.floorplan{
    .floor{
        li{
            position: relative;
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 10px 0;
            border-bottom: 1px solid #E5E5E5;
            &:first-child{
                border-top: 1px solid #E5E5E5;
            }
        }
    }
    .table{
        border-top: 2px solid #000000;
        border-bottom: 2px solid #000000;
        >:not(:first-child){
            border-top: 0;
        }
        thead{
            tr{
                th{
                    border-bottom: .5px solid #00000050;
                    font-weight: normal;
                }
            }
        }
        tbody{
            tr{
                td{
                    border-bottom: .5px solid #00000050;
                    i{
                        color: var(--clater);
                    }
                }
            }
        }
    }
    .section-body{
        margin-top: 5rem;
        .accordion{
            .accordion-item{
                margin: 16px 0;
                border-radius: 0;
                border: 1px solid #E0E0E0;
                box-sizing: border-box;
                .accordion-header{
                    .accordion-button{
                        color: var(--black);
                        background-color: var(--white);
                        box-shadow: none;
                        &::before, &::after{
                            background-color: var(--black);
                            content: "";
                            height: 2px;
                            position: absolute;
                            top: 10px;
                            right: 0;
                            transition: all .13333s ease-in-out;
                            width: 15px;
                        }
                        &::before{
                            transform: rotate(90deg) translate3d(14px,14px,0);
                        }
                        &::after{
                            transform: rotate(-180deg) translate3d(14px,-14px,0);
                        }
                        &[aria-expanded=true]{
                            &::before{
                                transform: rotate(45deg) translate3d(0px,20px,0);
                            }
                            &::after{
                                transform: rotate(-45deg) translate3d(-20px,0px,0);
                            }
                        }
                    }
                }
                .accordion-body{
                    font-size: 14px;
                    color: #C4C4C4;
                    ul{
                        list-style: decimal;
                    }
                }
            }
        }
    }
}