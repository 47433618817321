.hero{
    width: 100%;
    background-color: transparent;
    display: flex;
    align-items: center;
    justify-content: center;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
}

.btn-wa{
    position: relative;
    padding: .8rem 2rem;
    border: 1px solid #861831;
    box-sizing: border-box;
    border-radius: 20px;
    &:hover, &:focus{
        background-color: #861831;
        color: var(--white);
    }
}

.read-more{
    a{
        font-size: 14px;
        color: #867BFF;
    }
}

.btn-consult{
    position: relative;
    padding: .8rem 2.5rem;
    border: 1px solid #861831;
    box-sizing: border-box;
    border-radius: 20px;
    background-color: #861831;
    color: var(--white);
    &:hover, &:focus{
        outline: none;
        box-shadow: none;
        background-color: var(--white);
        color: #861831;
        border: 1px solid #861831;
    }
}